import { memo, useEffect, useState } from 'react';
import type { Node } from 'react';

import { Field, Form, Formik } from 'formik';
import { useId, VisuallyHidden } from 'react-aria';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { hasURLProtocol } from '@braindate/util/lib/url';

import LazyPasswordSupportMain from 'src/shared/app/authentication/component/support/password/LazyPasswordSupportMain';
import messages from 'src/shared/app/authentication/l10n/authenticationL10n';
import { passwordFieldMaxLength } from 'src/shared/app/authentication/settings/authenticationSettings';
import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import Markdown from 'src/shared/app/base/component/text/Markdown';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  passwordSupportRoute,
  signupRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import NoteText from 'src/shared/ui/component/text/NoteText';

import styles from './AuthenticationBase.style';
import AuthenticationPasswordToggle from './AuthenticationPasswordToggle';
import AuthenticationSignupButton from './AuthenticationSignupButton';

type Props = {
  type: string | null | undefined;
  label?: string;
  placeholder?: string;
  note?: string;
  submitCta: string;
  handleSubmit: (arg0: Record<string, any>) => Promise<any>;
  isPasswordSet: boolean;
  hideHelper?: boolean;
  forgotPasswordURL?: string | null | undefined;
};
const useStyles = createUseThemeStyles(styles);

const AuthenticationPasswordForm = ({
  type: propType,
  label,
  placeholder,
  note,
  submitCta,
  handleSubmit,
  isPasswordSet,
  hideHelper,
  forgotPasswordURL,
}: Props): Node => {
  const type = propType || 'password';
  const isTypePassword = type === 'password';

  /*
   |----------------------------------------------------------------------------
   | State
   |----------------------------------------------------------------------------
   */
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  /*
  |----------------------------------------------------------------------------
  | Hooks
  |----------------------------------------------------------------------------
  */
  const submitErrorId = useId();
  const [passwordSupportPath] = useRoutes(passwordSupportRoute, signupRoute);
  const intl = useIntl();
  const passwordSchema = Yup.string().required(
    isTypePassword
      ? intl.formatMessage(messages.passwordRequiredError)
      : undefined,
  );
  const schema = Yup.object().shape({
    password: passwordSchema,
  });

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    LazyPasswordSupportMain.preload();
  }, []);

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const onPasswordToggle = () =>
    setIsPasswordVisible((prevState) => !prevState);

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const toggleElt = isTypePassword && (
    <AuthenticationPasswordToggle
      layoutClass={classes.toggle}
      isPasswordVisible={isPasswordVisible}
      handleToggle={onPasswordToggle}
    />
  );
  const helperElt =
    !hideHelper &&
    isTypePassword &&
    isPasswordSet &&
    forgotPasswordURL &&
    hasURLProtocol(forgotPasswordURL) ? (
      <a href={forgotPasswordURL}>
        {intl.formatMessage(messages.forgotPasswordLink)}
      </a>
    ) : (
      <Link to={forgotPasswordURL || passwordSupportPath}>
        {intl.formatMessage(messages.forgotPasswordLink)}
      </Link>
    );
  const noteElt = note && (
    <NoteText layoutClass={classes.footer}>
      <Markdown>{note}</Markdown>
    </NoteText>
  );
  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={schema}
      validateOnBlur
      onSubmit={(values, { setSubmitting, setFieldError }) =>
        handleSubmit(values).catch(({ errors }) => {
          setSubmitting(false);
          const [error] = Object.values(errors || {});

          if (error) {
            setFieldError('password', String(error));
          }
        })
      }
    >
      {({ dirty, isSubmitting }) => (
        <Form className={classes.form} noValidate>
          <div className={classes.group}>
            <Field
              id="password"
              name="password"
              type={isPasswordVisible ? 'text' : type}
              component={FormikFormGroup}
              componentOpts={{
                label: label || intl.formatMessage(messages.passwordLabel),
              }}
              maxLength={passwordFieldMaxLength}
              placeholder={placeholder}
              required
              autoComplete="off"
              autoFocus
            />
            {toggleElt}
          </div>

          {helperElt ? (
            <div className={classes.support}>{helperElt}</div>
          ) : null}

          <div className={classes.submit}>
            {!dirty && (
              <VisuallyHidden id={submitErrorId}>
                {intl.formatMessage(messages.passwordRequiredError)}
              </VisuallyHidden>
            )}

            <PrimaryButton
              layoutClass={classes.nextButton}
              aria-disabled={!dirty}
              isSubmitting={isSubmitting}
              aria-describedby={!dirty ? submitErrorId : undefined}
              isFetching={isSubmitting}
            >
              {submitCta}
            </PrimaryButton>
          </div>
          <AuthenticationSignupButton />
          {noteElt}
        </Form>
      )}
    </Formik>
  );
};

export default memo<Props>(AuthenticationPasswordForm);
