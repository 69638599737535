import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './InstructionText.style';

type Props = {
  layoutClass?: string;
  withError?: boolean;
  children?: Node;
  left?: boolean;
  id?: string; // Self passed
};
const useStyles = createUseThemeStyles(styles);

const InstructionText = ({
  layoutClass,
  withError,
  left,
  children,
  id,
}: Props): Node => {
  const classes = useStyles();
  // Classes
  const rootClasses = classNames(classes.root, layoutClass, {
    [`${classes['root-withError']}`]: withError,
    [`${classes['root-left']}`]: left,
  });
  return (
    <div className={rootClasses} id={id}>
      {children}
    </div>
  );
};

export default InstructionText;
