import { memo } from 'react';
import type { Node } from 'react';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Tooltip } from '@mui/material';

import Markdown from 'src/shared/app/base/component/text/Markdown';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './AuthenticationContainer.style';

type Props = {
  title: string;
  legend?: string;
  children: Node;
};
const useStyles = createUseThemeStyles(styles);

const AuthenticationContainer = ({ title, legend, children }: Props): Node => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1 className={classes.title}>
        <Markdown>{title}</Markdown>

        {legend && (
          <Tooltip title={<Markdown>{legend}</Markdown>}>
            <span className={classes.tooltipIcon} tabIndex="0">
              <QuestionMarkIcon />
            </span>
          </Tooltip>
        )}
      </h1>

      {children}
    </div>
  );
};

export default memo<Props>(AuthenticationContainer);
