import { em, rem } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { gutter } from 'src/shared/app/base/style/token/grid';
import { disabledOpacity } from 'src/shared/app/base/style/token/opacity';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {},
  section: {
    position: 'relative',
    marginTop: rem(8, baseFontSize),
    padding: [0, gutter],
  },
  header: {
    width: '100%',
  },
  form: {
    position: 'relative',
    margin: 0,
  },
  'form-isLong': {
    paddingTop: 64,
  },
  'form-isDisabled': {
    opacity: disabledOpacity,
    pointerEvents: 'none',
  },
  group: {
    marginTop: 8,
    position: 'relative',
  },
  toggle: {
    position: 'absolute',
    bottom: 8,
    right: 12,
  },
  email: {
    marginBottom: 0,
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
    margin: [em(15, baseFontSize), 0, 0, 0],
    [tablet]: {
      margin: [em(32, baseFontSize), 0, em(20, baseFontSize)],
    },
    '& button': {
      marginLeft: 0,
    },
  },
  signup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: em(25, baseFontSize),
  },
  support: {
    textAlign: 'right',
    '& a': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.linkColor,
    },
  },
  footer: {
    display: 'block',
    marginTop: rem(3),
  },
  forgotPassword: {
    background: 'none',
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    font: ({ theme }: ThemeFunction<unknown>) => theme.secondaryFontFamily,
  },
  passwordInputHidden: {
    // Fix to be able to log with a password manager without "filling the form" 2 times
    opacity: 0,
    height: 0,
    margin: 0,
    pointerEvents: 'none',
  },
  separator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    fontWeight: 700,
    color: ({ theme }: ThemeFunction<unknown>) => theme.favoriteIconColor,
    margin: [rem(20), 0],
    fontSize: rem(14, baseFontSize),
    '&>hr': {
      width: '40%',
    },
  },
  nextButton: {
    width: '100%',
    marginLeft: 0,
    borderRadius: ({ theme }: ThemeFunction<unknown>) =>
      theme.buttonBorderRadius,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    gap: '8px',
  },
};
