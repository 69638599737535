import { memo, useRef } from 'react';
import type { Node } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import classNames from 'classnames';
import { useButton } from 'react-aria';
import { useIntl } from 'react-intl';

import messages from 'src/shared/app/authentication/l10n/authenticationL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './AuthenticationPasswordToggle.style';

type Props = {
  layoutClass?: string;
  isPasswordVisible: boolean;
  handleToggle: () => void;
  label?: string;
};
const useStyles = createUseThemeStyles(styles);

const AuthenticationPasswordToggle = ({
  isPasswordVisible,
  handleToggle,
  layoutClass,
  label,
}: Props): Node => {
  const ref = useRef();
  const intl = useIntl();

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const onToggle = () => {
    handleToggle();
  };

  const { buttonProps } = useButton(
    {
      elementType: 'svg',
      onPress: onToggle,
    },
    ref,
  );

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();
  const rootClasses = classNames(layoutClass, classes.root);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const Component = isPasswordVisible ? VisibilityOffIcon : VisibilityIcon;
  return (
    <Component
      {...buttonProps}
      role="switch"
      aria-checked={isPasswordVisible}
      className={rootClasses}
      ref={ref}
      aria-hidden={false}
      aria-label={`${intl.formatMessage(messages.passwordToggle, {
        isVisible: isPasswordVisible,
      })} ${label || ''}`}
    />
  );
};

export default memo<Props>(AuthenticationPasswordToggle);
