import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    border: ({ theme }: ThemeFunction<unknown>) =>
      `1px solid ${theme.calendarDisableButtonColor}`,
    marginTop: 15,
    marginBottom: 15,
    borderRadius: 10,
    padding: [7.5, 10],
    lineHeight: 19 / baseFontSize,
    textAlign: 'center',
    WebkitFontSmoothing: 'antialiased',
    '&.p': {
      margin: 0,
    },
  },
  'root-withError': {
    color: ({ theme }: ThemeFunction<unknown>) => theme.errorMessageColor,
  },
  'root-left': {
    textAlign: 'left',
  },
};
