import { em } from 'polished';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'block',
    padding: [0, 0, em(0.5)],
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    fontSize: 16,
    fontWeight: 500,
    textDecoration: 'none',
    WebkitFontSmoothing: 'antialiased',
    cursor: 'pointer',
    '&:visited': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    },
    '&:hover': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    },
    '&:active': {
      textDecoration: 'none',
    },
    '&:focus': {
      outlineWidth: 0,
      border: ({ theme }: ThemeFunction<unknown>) =>
        `solid 1px ${theme.accentColor}`,
    },
  },
};
