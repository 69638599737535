import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './NoteText.style';

type Props = {
  layoutClass?: string;
  children: Node;
};
const useStyles = createUseThemeStyles(styles);

const NoteText = ({ layoutClass, children }: Props): Node => {
  const classes = useStyles();
  return (
    <small className={classNames(classes.root, layoutClass)}>{children}</small>
  );
};

export default NoteText;
